import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 582.000000 569.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,569.000000) scale(0.100000,-0.100000)">
          <path d="M1230 2960 l0 -210 45 0 45 0 0 60 0 60 48 0 c46 -1 48 -1 87 -60 39
-59 40 -60 87 -60 27 0 48 2 48 6 0 3 -19 33 -41 66 l-42 61 27 20 c85 62 72
198 -24 248 -28 16 -57 19 -157 19 l-123 0 0 -210z m236 115 c19 -12 24 -24
24 -55 0 -53 -26 -70 -107 -70 l-63 0 0 68 c0 38 3 72 8 76 11 13 111 -1 138
-19z"/>
<path d="M1670 2960 l0 -210 50 0 50 0 0 210 0 210 -50 0 -50 0 0 -210z"/>
<path d="M1870 2960 l0 -210 113 0 c171 0 245 28 287 110 44 87 31 186 -33
248 -54 52 -91 62 -237 62 l-130 0 0 -210z m269 114 c38 -23 61 -64 61 -111 0
-87 -56 -133 -161 -133 l-69 0 0 136 0 137 70 -6 c38 -3 83 -13 99 -23z"/>
<path d="M2380 3163 c-1 -5 -1 -98 -1 -208 l0 -200 45 -3 45 -3 3 127 3 128
104 -127 c100 -123 105 -127 142 -127 l39 0 0 210 0 210 -45 0 -45 0 0 -120
c0 -66 -4 -120 -8 -120 -4 0 -52 54 -105 120 -95 117 -99 120 -137 120 -22 0
-40 -3 -40 -7z"/>
<path d="M2997 3163 c-12 -20 -177 -397 -177 -404 0 -5 19 -9 43 -9 41 0 44 2
65 45 l22 46 102 -3 101 -3 16 -42 c15 -42 16 -43 63 -43 26 0 48 3 48 7 0 3
-41 98 -91 210 l-91 203 -49 0 c-26 0 -50 -3 -52 -7z m86 -161 c15 -38 27 -74
27 -80 0 -13 -115 -17 -124 -4 -5 9 53 152 62 152 4 0 19 -31 35 -68z"/>
<path d="M3330 2961 l0 -211 113 0 c141 0 204 17 255 68 93 93 72 258 -42 324
-37 21 -56 23 -183 26 l-143 4 0 -211z m269 113 c38 -23 61 -64 61 -111 0 -87
-56 -133 -161 -133 l-69 0 0 128 c0 71 3 132 8 136 12 13 130 -2 161 -20z"/>
<path d="M3871 2965 c-50 -113 -91 -207 -91 -210 0 -3 20 -5 45 -5 45 0 46 1
65 45 l19 45 99 0 98 0 22 -45 c21 -43 23 -45 67 -45 25 0 45 4 45 9 0 5 -40
98 -88 207 l-89 199 -50 3 -50 3 -92 -206z m172 36 c15 -38 27 -74 27 -80 0
-7 -22 -11 -60 -11 -41 0 -60 4 -60 12 0 19 52 148 60 148 3 0 18 -31 33 -69z"/>
<path d="M4230 3135 l0 -34 63 -3 62 -3 5 -170 5 -170 47 -3 48 -3 2 173 3
173 68 3 67 3 0 34 0 35 -185 0 -185 0 0 -35z"/>
<path d="M4671 2965 c-50 -113 -91 -207 -91 -210 0 -3 21 -5 46 -5 44 0 46 1
65 45 l20 45 97 0 98 0 22 -45 c21 -43 23 -45 67 -45 25 0 45 4 45 9 0 5 -40
98 -89 208 l-89 198 -49 3 -50 3 -92 -206z m172 37 c15 -38 27 -74 27 -80 0
-8 -20 -12 -60 -12 -41 0 -60 4 -60 13 0 16 53 147 60 147 3 0 17 -31 33 -68z"/>
<path d="M478 3040 c-22 -14 -26 -111 -5 -128 11 -9 13 -3 11 30 -1 23 2 52 7
64 l8 23 243 -4 c194 -4 239 -2 223 8 -27 16 -462 22 -487 7z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
